import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { SanityWebsiteThemeProvider } from "@web-punks/contents-sanity"

export const useSanityThemesQuery = () =>
  useStaticQuery(graphql`
    query WebsiteThemeQuery {
      allSanityWebsiteTheme {
        nodes {
          data {
            main {
              name
              isDefault
            }
            palette {
              action {
                active {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                disabled {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                focus {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                hover {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                selected {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
              }
              custom {
                id
                value {
                  dark {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  light {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  main {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                }
              }
              error {
                dark {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                light {
                  hex
                  rgb {
                    r
                    g
                    a
                    b
                  }
                }
                main {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
              }
              info {
                dark {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                light {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                main {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
              }
              primary {
                main {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                light {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                dark {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
              }
              secondary {
                dark {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                light {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                main {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
              }
              success {
                dark {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                light {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                main {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
              }
              warn {
                dark {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                light {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                main {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
              }
            }
            styles {
              borders {
                borderRadius {
                  medium
                  large
                  default
                }
              }
              shadows {
                disabled
                style
              }
            }
            backgrounds {
              dark {
                color {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                image {
                  asset {
                    url
                  }
                }
              }
              light {
                color {
                  hex
                  rgb {
                    r
                    g
                    b
                    a
                  }
                }
                image {
                  asset {
                    url
                  }
                }
              }
            }
            breakpoints {
              mobileBreakpoint
            }
            components {
              buttons {
                contained {
                  borderWidth
                  boxShadow
                  fontWeight
                  letterSpacing
                  paddings {
                    bottom
                    left
                    right
                    top
                  }
                }
                default {
                  borderWidth
                  boxShadow
                  fontWeight
                  letterSpacing
                  paddings {
                    top
                    right
                    left
                    bottom
                  }
                }
                outlined {
                  borderWidth
                  fontWeight
                  boxShadow
                  letterSpacing
                  paddings {
                    top
                    right
                    left
                    bottom
                  }
                }
              }
            }
            font {
              styles {
                colors {
                  disabled {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  hint {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  primary {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  secondary {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                }
                defaults {
                  fontFamily
                  fontWeight
                }
              }
              typography {
                body1 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  weight
                  size
                }
                body2 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h1 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h2 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h3 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h4 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h5 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h6 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                overline {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                subtitle1 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                subtitle2 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
              }
              typographyMobile {
                body1 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  weight
                  size
                }
                body2 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h1 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h2 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h3 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h4 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h5 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                h6 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                overline {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                subtitle1 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
                subtitle2 {
                  color {
                    hex
                    rgb {
                      r
                      g
                      b
                      a
                    }
                  }
                  letterSpacing
                  lineHeight
                  size
                  weight
                }
              }
            }
          }
        }
      }
    }
  `)

export const SiteThemeProvider = ({ children }: any) => {
  const settings = useSanityThemesQuery()
  return (
    <SanityWebsiteThemeProvider value={settings}>
      {children}
    </SanityWebsiteThemeProvider>
  )
}
