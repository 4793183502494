import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { SanityWebsiteTrackingProvider } from "@web-punks/contents-sanity"

export const useSanityTrackingQuery = () =>
  useStaticQuery(graphql`
    query WebsiteTrackingQuery {
      allSanityWebsiteTracking {
        nodes {
          data {
            google {
              analyticsId
              gtmId
              optimizeId
            }
          }
        }
      }
    }
  `)

export const SiteTrackingProvider = ({ children }: any) => {
  const settings = useSanityTrackingQuery()
  return (
    <SanityWebsiteTrackingProvider value={settings}>
      {children}
    </SanityWebsiteTrackingProvider>
  )
}
