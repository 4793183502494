import React from "react"
import { MuiProviders } from "@web-punks/ui-mui"
import CoreProviders from "./punks/core"
import SanityPunksProviders from "./punks/sanity"
// import Gtm from "./scripts/gtm"

interface Params {
  element: any
}

export const wrapRootElement = ({ element }: Params) => {
  return (
    <>
      <CoreProviders>
        <SanityPunksProviders>
          <MuiProviders>{element}</MuiProviders>
        </SanityPunksProviders>
      </CoreProviders>
      {/* <Gtm /> */}
    </>
  )
}
