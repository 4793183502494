// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-game-tsx": () => import("./../../../src/templates/game.tsx" /* webpackChunkName: "component---src-templates-game-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-slide-tsx": () => import("./../../../src/templates/slide.tsx" /* webpackChunkName: "component---src-templates-slide-tsx" */),
  "component---src-templates-typ-tsx": () => import("./../../../src/templates/typ.tsx" /* webpackChunkName: "component---src-templates-typ-tsx" */)
}

